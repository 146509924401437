import React, { useState } from 'react';
import { Grid, TextField, Button, Box, Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import '@fontsource/poppins'; // Ensure that Poppins font is imported
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
});

const ContactForm = () => {
  const [eventType, setEventType] = useState('social');

  const handleEventTypeChange = (event, newEventType) => {
    setEventType(newEventType);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 4, maxWidth: 1200, margin: 'auto', backgroundColor: '#fff', boxShadow: 3, borderRadius: 2, marginTop:'50px' }}>
        <Grid container spacing={4} justifyContent="space-between">
          {/* Left Side - Contact Form */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Connect with us →
            </Typography>

            <Typography variant="subtitle1" gutterBottom>
              Personal Information
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="First name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Last name"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone No"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Event Type
                </Typography>
                <ToggleButtonGroup
                  value={eventType}
                  exclusive
                  onChange={handleEventTypeChange}
                  fullWidth
                >
                  <ToggleButton value="corporate">Corporate</ToggleButton>
                  <ToggleButton value="networking">Networking</ToggleButton>
                  <ToggleButton value="social">Social</ToggleButton>
                  <ToggleButton value="entertainment">Entertainment</ToggleButton>
                </ToggleButtonGroup>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Your Message
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    backgroundColor: '#ff4081',
                    color: '#fff',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#e91e63',
                    },
                  }}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Right Side - Image */}
          <Grid item xs={12} md={5}>
            <img
              src="image/event2.JPG"
              alt="Team"
              style={{
                width: '100%',
                height:'100%',
                borderRadius: '10px',
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default ContactForm;
