import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import '@fontsource/poppins'; 

const Footer = () => {
  return (
    <Box display="flex" justifyContent="space-between" p={2} bgcolor="#0B1D30" color="white" sx={{ fontFamily: 'Poppins, sans-serif' }}> 
      <Box display={'flex'} alignItems={'center'}>
        {['Home', 'About', 'Services'].map((item, index) => (
          <Link key={index} href="#" color="inherit" underline="none" sx={{ mr: 2 }}>
            {item}
          </Link>
        ))}
      </Box>
      <Box textAlign="right">
        <Typography sx={{ fontFamily: 'Poppins, sans-serif' }}>2B/6, Sadayanikuppam Battai, Thiruvottiyur,</Typography>
        <Typography sx={{ fontFamily: 'Poppins, sans-serif' }}> Chennai - 600019</Typography>
        
      </Box>
    </Box>
  );
};

export default Footer;
