import React from 'react';
import { Container, Grid, Box, Typography, Button } from '@mui/material';

const UpcomingEvents = () => {
  // Example images; replace these URLs with your actual image paths.
  const mainEventImage = 'image/upeve.png'; 
  const smallEventImage1 = 'image/upeve1.png';
  const smallEventImage2 = 'image/upeve1.png';

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
    <Typography variant="h4" textAlign="center" fontWeight="bold" sx={{ mb: 2, fontFamily: 'Poppins, sans-serif' }}>
      Upcoming Events
    </Typography>
    <Typography variant="body1" textAlign="center" sx={{ mb: 1,  fontFamily: 'Poppins, sans-serif' }}>
      From venue scouting to event decorations,
    </Typography>
    <Typography variant="body1" textAlign="center" sx={{ mb: 2,  fontFamily: 'Poppins, sans-serif' }}>
      we offer everything you need for a perfect event.
    </Typography>

    <Grid container justifyContent="center" alignItems="flex-start" spacing={10}>
  {/* Main Event Image */}
  <Grid item xs={12} md={7}>
    <Box
      component="div"
      sx={{
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '12px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '120%',
        marginBottom: 4,
        '&:hover .details': { opacity: 1 },
      }}
    >
      <Box
        component="img"
        src={mainEventImage}
        alt="Freelancers Monthly Meetup"
        sx={{ width: '100%', height: 'auto' }}
      />
      {/* Hover Details */}
      <Box
        className="details"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          color: 'white',
          opacity: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'opacity 0.3s ease',
        }}
      >
        <Typography variant="h6" sx={{ fontFamily: 'Poppins, sans-serif' }}>Freelancers Monthly Meetup</Typography>
        <Typography variant="body1"sx={{ fontFamily: 'Poppins, sans-serif' }}>27th July 2024</Typography>
        <Button variant="contained" sx={{ mt: 2,  fontFamily: 'Poppins, sans-serif' }}>Learn More</Button>
      </Box>
    </Box>
  </Grid>

  {/* Small Event Images */}
  <Grid item xs={12} md={5}>
    <Grid container spacing={2}>
      {[smallEventImage1, smallEventImage2].map((image, index) => (
        <Grid item xs={12} key={index}>
          <Box
            component="div"
            sx={{
              position: 'relative',
              overflow: 'hidden',
              borderRadius: '12px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              width: '41%',
              margin: 'auto',
              '&:hover .details': { opacity: 1 },
            }}
          >
            <Box
              component="img"
              src={image}
              alt={`Event ${index + 1}`}
              sx={{ width: '100%', height: 'auto' }}
            />
            {/* Hover Details */}
            <Box
              className="details"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                opacity: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'opacity 0.3s ease',
              }}
            >
              <Typography variant="h6">Event {index + 1}</Typography>
              <Button variant="contained" sx={{ mt: 2 }}>Learn More</Button>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  </Grid>
</Grid>

  </Container>
  );
};

export default UpcomingEvents;
